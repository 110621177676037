

const APP_STRING = {
    main_navs: ['digital ads', 'websites', 'branded content', 'bloomr.sg', 'contact us'],
    top_left_nav_title: 'Mediacorp Showcase',
    landing_title: 'Mediacorp Showcase',
    landing_description: [
        /// Each item like break new line.
        'Mediacorp Creative Central offers a complete one-stop suite of creative and marketing solutions for clients.',
        ' This website showcases our digital executions; searchable by industry and ad type.',
        'Do get in touch if you have any questions regarding creative solutions for your business.'
    ],
    landing_cta_digital_ads: "digital ads",
    landing_cta_websites: "websites",
    landing_cta_content: "branded content",
    landing_cta_bloomr: "bloomr.sg",
    landing_cta_contact: "contact us",
    landing_sharing: [
        'Mediacorp Creative Central offers a complete one-stop suite of creative and marketing solutions for clients.',
        'This website showcases our digital executions; searchable by industry and ad type.',
        'Do get in touch if you have any questions regarding creative solutions for your business.'
    ],
    home: 'home',
    footer_address: 'find us at Mediacorp Campus, 1 Stars Avenue, Singapore 138507',
    footer_phone: 'Call: (65) 6359 7583',
    footer_email: 'Email: creativecentral@mediacorp.com.sg',
    footer_copy_right: 'Copyright © #YEAR MEDIACORP Creative Central.',
    button_search: "Search",
    button_clear_search: "Clear",
    feature: "Feature",
    format: "Format",
    industry: "Industry",
    default_dropdown_format: "All Options",
    default_dropdown_feature: "All Options",
    default_dropdown_device: "All Options",
    default_dropdown_industry: "All Options",
    filter_no_result_title: "we're sorry",
    filter_no_result_message: "We can't seen to find any creatives that match your search",
    page_digital_ads_name: "digital ads",
    page_websites_name: "websites",
    page_content_name: "branded content",
    page_bloomr_name: "bloomr.sg",
    page_contact_name: "contact us",
    page_contact_thank_you: "Thank you",
    detail_label_client: 'Client',
    detail_label_project_name: 'Project Name',
    detail_label_background_idea: 'Background/Idea',
    detail_label_format: 'Format',
    detail_label_device: 'Device',
    detail_label_features: 'Features',
    detail_label_features_bloorm: 'Platform',
    detail_label_industry: 'Industry',
    detail_button_view: 'View the Creative',
    detail_button_back: 'Back',
    detail_button_view_specification: 'View the Ad Specifications',
    contact_description: [
        'Interested to advertise on Mediacorp?',
        'We are ready to assist and answer your questions!',
        'For all enquires, please fill out the form below.'
    ],
    contact_form_name: 'Name',
    contact_form_email: 'Email',
    contact_form_phone: 'Phone',
    contact_form_message: 'Message',
    contact_form_submit: 'submit',
    consent_privacy: 'I have read and agreed to the ##PRIVACY_REPLACEMENT## and acknowledged that my personal data will be processed for the purposes listed.',
    consent_privacy_replacement: 'privacy policy',
    consent_privacy_url: 'https://mediacorp.sg/en/privacy-policy-5933440',
    thank_you: [
        'We have received your enquiry and will reply back to you shortly.',
    ],
    error_empty_name: 'Please fill in your name',
    error_empty_email: 'Please fill in your email',
    error_email_format: 'Invalid email',
    error_empty_phone: 'Please fill in your contact number',
    error_phone_length: 'Must be 8 digits',
    error_empty_message: 'Please enter your message',
    error_message_length: 'Your message has exceeded 500 characters',
    error_empty_consent: 'Please click on the checkbox to proceed'
};

Object.freeze(APP_STRING);

export default APP_STRING;